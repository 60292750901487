
import { defineComponent, inject, ref, watch } from "vue";
import { Database } from "vuebase";
import { Artist, Product } from "../main";
import marked from "marked";
import { useRoute } from "vue-router";
import Masonry from "masonry-layout";

export default defineComponent({
    name: "Artist",
    setup() {
        const database = inject(Database.InjectionKey);
        const artistId = useRoute().params.id as string;
        const artist = database?.collection<Artist>("artists").document(artistId);
        const products = database?.collection<Product>("products").documents();
        const randomizedProducts = ref<Database.Document<Product>[]>();
        const gallery = ref();

        watch(
            () => products,
            () => {
                if (products && products.length > 0) {
                    randomizedProducts.value = [...products].sort(() => 0.5 - Math.random());
                }
            },
            { deep: true, immediate: true },
        );

        function resizeGallery() {
            new Masonry(gallery.value, {
                itemSelector: ".print",
                percentPosition: true,
            });
        }

        return {
            artist,
            products,
            randomizedProducts,
            gallery,
            marked,
            resizeGallery,
        };
    },
});
