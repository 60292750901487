<template>
    <c-panel>
        <c-grid>
            <c-grid-item class="width-25-all width-33-md width-100-sm" v-if="artist?.data?.profilePic">
                <div class="artist">
                    <div class="profile-pic">
                        <c-image :image="artist.data.profilePic" />
                    </div>
                </div>
            </c-grid-item>
            <c-grid-item class="width-05-all"> </c-grid-item>
            <c-grid-item class="width-grow-all">
                <div class="name">
                    <h1>{{ artist?.data?.name }}</h1>
                    <p class="text-light">Based in {{ artist?.data?.location }}</p>
                    <p class="mt-4" v-if="artist?.data?.bio" v-html="marked(artist?.data?.bio)"></p>
                </div>
            </c-grid-item>
        </c-grid>
    </c-panel>
    <c-panel>
        <div class="gallery" ref="gallery">
            <template v-for="product in randomizedProducts" :key="product.id">
                <div
                    class="print"
                    v-if="product.data && product.data.category == 'Gallery Print' && product.data.artist == artist?.id"
                >
                    <img
                        @load="resizeGallery()"
                        @click="$router.push('/product/' + product.id)"
                        :src="product.data.images[0].thumbnail"
                    />
                </div>
            </template>
        </div>
    </c-panel>
</template>

<script lang="ts">
import { defineComponent, inject, ref, watch } from "vue";
import { Database } from "vuebase";
import { Artist, Product } from "../main";
import marked from "marked";
import { useRoute } from "vue-router";
import Masonry from "masonry-layout";

export default defineComponent({
    name: "Artist",
    setup() {
        const database = inject(Database.InjectionKey);
        const artistId = useRoute().params.id as string;
        const artist = database?.collection<Artist>("artists").document(artistId);
        const products = database?.collection<Product>("products").documents();
        const randomizedProducts = ref<Database.Document<Product>[]>();
        const gallery = ref();

        watch(
            () => products,
            () => {
                if (products && products.length > 0) {
                    randomizedProducts.value = [...products].sort(() => 0.5 - Math.random());
                }
            },
            { deep: true, immediate: true },
        );

        function resizeGallery() {
            new Masonry(gallery.value, {
                itemSelector: ".print",
                percentPosition: true,
            });
        }

        return {
            artist,
            products,
            randomizedProducts,
            gallery,
            marked,
            resizeGallery,
        };
    },
});
</script>

<style lang="scss">
@import "../../../coho-ui/src/styles/variables.scss";
@import "../../../coho-ui/src/styles/mixins.scss";

.artist {
    cursor: pointer;

    .profile-pic {
        border-radius: 1000px;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
        transition: all 300ms ease;
        overflow: hidden;

        &:hover {
            transform: scale(1.02);
        }
    }

    .name {
        margin-top: 12px;
        text-align: center;
    }
}

.gallery {
    margin: -5px;
}

.print {
    box-sizing: border-box;
    padding: 5px;
    cursor: pointer;
    width: 33%;

    @include screen("md") {
        width: 50%;
    }

    @include screen("sm") {
        width: 100%;
    }

    img {
        display: block;
        object-fit: contain;
        width: 100%;
    }
}
</style>
